import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  wrapper: {
    width: '200px',
    height: 'fit-content',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
  },
  name: {
    fontWeight: 600,
  },
  priceWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'flex-end',
  },
  price: {
    fontSize: '25px',
  },
  extraInfo: {
    textAlign: 'center',
  },
  dueDate: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mobileOption: {
    display: 'none',
  },
  card: {
    borderRadius: '18px',
    padding: '1em 1.5em',
    color: theme.midnightGreen,
    border: `2px solid ${theme.midnightGreen}99`,
    '&.selected': {
    },
    '&.free': {
      backgroundColor: `${theme.grey}0D`,
    },
    '&.premium': {
      backgroundColor: `${theme.green}21`,
    },
    '&.schools': {
      backgroundColor: `${theme.airBlue}1A`,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1em',
    marginBottom: '15px',
  },
  planTarget: {
    color: 'grey',
    fontSize: '14px',
    marginRight: 'auto',
  },
  planPrice: {
    fontSize: '24px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.25em',
    marginBottom: '15px',
  },
  planSchools: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '15px',
  },
  planDescription: {
    fontSize: '14px',
    marginBottom: '15px',
  },

  perMonth: {
    fontSize: '12px',
    fontWeight: '400',
  },
  planType: {
    borderRadius: '9px',
    padding: '0.5em 1.5em',
    backgroundColor: 'red',
    fontSize: '12px',
    fontWeight: 600,
    '&.free': {
      backgroundColor: 'grey',
      color: 'white',
    },
    '&.premium': {
      backgroundColor: theme.midnightGreen,
      color: theme.green,
    },
    '&.schools': {
      backgroundColor: theme.green,
      color: theme.midnightGreen,
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: '0.5em',
    marginTop: '1em',
  },
  button: {
    backgroundColor: theme.midnightGreen,
    color: theme.green,
    padding: '0.5em 1.5em',
    display: 'flex',
    gap: '1em',
    width: 'fit-content',
    '&.schools': {
      backgroundColor: theme.green,
      color: theme.midnightGreen,
    },
  },
  schoolLabel: {
    fontSize: '10px',
    color: 'grey',
  },
  featuresWrapper: {
    display: 'grid',
    gridTemplateColumns: '10% 90%',
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
    fontSize: '14px',
  },
  icon: {
    color: theme.green,
  },
  yearlyExtraText: {
    opacity: '80%',
    marginBottom: '1em',
    fontSize: '14px',
    marginTop: '-1em',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    wrapper: {
      display: 'none',
    },
    mobileOption: {
      display: 'unset',
    },
  },
}));
