import LocalizedStrings from 'react-localization';

const lang = new LocalizedStrings({
  en: {
    add: 'Add',
    create: 'Create',
    publish: 'Publish',
    submit: 'Submit',
    hide: 'Hide',
    edit: 'Edit',
    cancel: 'Cancel',
    erase: 'Erase',
    'delete': 'Delete',
    remove: 'Remove',
    saveChanges: 'Save changes',
    changesSaved: 'The changes made were saved successfully',
    copy: 'Copy',
    oops: 'Oops! Something went wrong!',
    date: 'Date',
    previous: 'Previous',
    next: 'Next',
    en: 'English',
    pt: 'Portuguese',
    esES: 'Spanish',
    ptBR: 'Portuguese (Brazil)',
    unauthorizedMessage: 'Looks like you\'re not yet qualified to be in this area... Maybe next year!',
    notFoundMessage: 'Oh no! It seems that you lost your way around our website... Maybe it\'s not that Intuitive.',
    errorMessage: 'Something wrong happened... We\'ll be looking into fixing it as soon as possible.',
    noPermissionSpace: 'You don\'t have the permissions to access this space.',
    home: 'Home',
    account: 'Account',
    signout: 'Sign out',
    send: 'Send',
    info: 'Info',
    help: 'Help',
    feedback: 'Feedback',
    showMore: 'Show more',
    chooseSpace: 'Select a space',
    changeLang: 'Change language',
    logout: 'Log out',
    or: 'Or',
    tutorial: 'Tutorials',
    confirm: 'Confirm',
    internetWentWrong: 'Something went wrong. Please check your internet connection and try again',
    month: 'month',
    save: 'Save',
    appKeywords: {
      classes: 'Classes',
      tests: 'Tests',
      threads: 'Threads',
      admin: 'Administrator',
      lesson: 'Lesson',
      subject: 'Subject',
      'class': 'Class',
      student: 'Student',
      students: 'Students',
      grade: 'Grade',
      personalAccount: 'Personal account',
      personalSpace: 'Personal space',
      groups: 'Groups',
      exercises: 'Exercises',
      explore: 'Explore',
      assessments: 'Assessments',
      rubrics: 'Rubrics',
      events: 'Events',
      synchronization: 'Synchronization',
      teachers: 'Teachers',
      exams: 'Exams',
    },
    login: {
      header: 'Login',
      description: 'Welcome to Intuitivo',
      emailLabel: 'E-mail or username',
      emailPlaceholder: 'Insert here your e-mail or username',
      emailError: 'You have to fill this field with your e-mail.',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insert here your password',
      passwordError: 'You have to fill this field with your password.',
      submitLogin: 'Login',
      wrongCredentials: 'Your e-mail or password don\'t match',
      forgotPassword: 'Forgot my password',
      dontHaveAccount: 'Don\'t have an account?',
      createOne: 'Create one',
      loginWithMicrosoft: 'Login with Microsoft',
      loginWithMicrosoftError: 'Please login with your Microsoft account',
      loginWithMicrosoftNoAccount: 'No account exists with that e-mail. Create an account now!',
      loginWithMicrosoftRegularAccountExists: 'This account is not linked to Microsoft. Please login with your credentials!',
      exceededMaxAttempts: 'You exceeded the maximum login attempts allowed. Click in forgot my password to continue',
      poweredBy: 'Powered by',
    },
    register: {
      header: 'Create account',
      description: 'Welcome to Intuitivo',
      nameLabel: 'Name',
      namePlaceholder: 'Insert here your name',
      nameEmptyError: 'The name can\'t be empty',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insert here your e-mail',
      emailEmptyError: 'The e-mail can\'t be empty',
      emailInvalidError: 'The e-mail introduced must be valid',
      emailExistsError: 'There is already an account with that e-mail address',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insert here your password',
      passwordLengthError: 'The password must have at least 8 characters',
      passwordSpecialCharsError: 'The password must contain at least one special character',
      passwordUpperCharsError: 'The password must contain at least one uppercase letter',
      passwordNumberError: 'The password must contain at least one number',
      repeatPasswordLabel: 'Repeat password',
      repeatPasswordPlaceholder: 'Insert here your password',
      passwordsDifferentError: 'The passwords must be equal',
      createAccount: 'Create account',
      alreadyHaveAccount: 'Already have an account?',
      login: 'Login',
      registerWithMicrosoft: 'Create account with Microsoft',
    },
    onboarding: {
      title: 'Welcome',
      header: 'Before you continue, help us improve your experience',
      doThisLater: 'Fill it later',
    },
    recover: {
      header: 'Recover password',
      description: 'An e-mail will be sent to your e-mail address where you will be able to change your password.',
      descriptionPassword: 'Type a new password to replace your current one.',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insert here your e-mail',
      emailSent: 'The e-mail was successfully sent. Please check your inbox',
      emailError: 'The provided e-mail is incorrect.',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insert here your password',
      passwordRepeatLabel: 'Repeat Password',
      passwordRepeatPlaceholder: 'Insert here your password again',
      passwordUpdated: 'The password was updated with success',
      passwordMinimumChars: 'The password must contain at least 8 characters, one letter and one number.',
      passwordsDifferentError: 'The passwords must be equal.',
      passwordLengthError: 'The password must have at least 8 characters',
      passwordSpecialCharsError: 'The password must contain at least one special character',
      passwordUpperCharsError: 'The password must contain at least one uppercase letter',
      passwordNumberError: 'The password must contain at least one number',
    },
    profile: {
      header: 'Account management',
      description: 'Here you can manage your account',
      fullName: 'Name',
      email: 'E-mail',
      updatePassword: 'Change password',
      passwordMissing: 'Both fields must be filled',
      passwordError: 'Passwords don\'t match',
      passwordHint: 'Use 8 or more characters with a mix of letters, numbers and special characters',
      newPassword: 'New password',
      confirmPassword: 'Confirm new password',
      newPasswordPlaceholder: 'Type here the new password',
      confirmPasswordPlaceholder: 'Type again the new password',
      userName: 'Username',
      editInformation: 'Edit information',
      nameRequiredError: 'The name field is required',
      emailRequiredError: 'The e-mail field is required',
      emailInvalidError: 'The e-mail field is invalid',
      profileInformation: {
        informationUpdateSuccess: 'The information was successfully updated',
      },
      profilePreferences: {
        profilePreferences: 'Profile preferences',
        subjects: 'Subjects',
        years: 'Years',
        country: 'Country',
        curriculum: 'Curriculum',
        selectCountry: 'Select a country',
        selectCurriculum: 'Select a curriculum',
        editProfilePreferences: 'Edit preferences',
        noSubjects: 'You have not selected any subject yet',
        noYears: 'You have not selected any year yet',
        personalSpace: 'Personal Space',
        personalSpaceDescription: 'A personal space is where you can create your contents separately from your school space and create groups with other member of the community.',
        personalSpaceCheckboxLabel: 'Activate personal space',
      },
      security: {
        securityHeader: 'Security',
        changePassword: 'Change password',
        linkWithMicrosoft: 'Link with Microsoft',
        unlinkMicrosoftAccount: 'Unlink Microsoft account',
        unlinkMicrosoftAccountInfo: 'Are you sure you want to unlink your Microsoft account? You will be able to reconnect at any time in the future.',
        unlinkMicrosoftAccountSuccess: 'Your Microsoft account was successfully unlinked',
        unlinkMicrosoftAccountDisabled: 'You need to create a password before unlinking your Microsoft account',
        emailAlreadyInUse: 'This email is already being used by another user',
        accountLinkedWithMicrosoftSuccess: 'Your Microsoft account was successfully linked',
        createPassword: 'Create password',
      },
      plans: {
        plan: 'My plan',
        plans: 'Plans',
        free: 'Free',
        basic: 'Basic',
        schools: 'Schools',
        forTeachers: 'For teachers',
        forSchools: 'For institutions',
        premium: 'Premium',
        nextPayment: 'Next payment',
        onRequest: 'On request',
        contactUs: 'Contact us!',
        recommendSchool: 'Recommend School',
        switchToPlan: 'Switch to this plan',
        switchPlan: 'Manage plan',
        cancelSubscription: 'Cancel subscription',
        cancelTip: (endDate) => `You will remain Premium until the end of the billing cycle on ${endDate}`,
        continueSubscription: 'Resume subscription',
        subscribedUntil: 'Subscribed until',
        planDescription: {
          free: 'The essentials of Intuitivo, available for all.',
          premium: 'The best assessment tool, without limits.',
          schools: 'The ultimate solution for educational institutions.',
        },
        monthly: 'Monthly',
        yearly: 'Yearly',
        billedMonthly: 'billed monthly',
        billedYearly: 'billed yearly',
        yourSubscription: 'Your subscription',
        total: 'Total',
        andPay: 'and pay',
        taxInfo: 'Tax included',
        includedTax: 'Tax included',
        alreadyOnThatPlan: 'You are already subscribed to that plan',
        discountPrimaryText: (discountName) => `${discountName} with `,
        discountHighlightText: (limitDate) => `a discount until ${limitDate}!`,
        discountTooltip: (discountName, limitDate, yearlyDiscount, monthlyDiscount) => `${discountName} campaign until ${limitDate}, with ${yearlyDiscount}% discount on the annual premium plan${monthlyDiscount ? ` and ${monthlyDiscount}% on the monthly premium plan.` : '.'}`,
        premiumUser: 'Premium User',
        getPremium: 'Get Premium',
        schoolsFeatures: {
          premium: 'Everything in Premium plan',
          adminPanel: 'Administration panel',
          schoolSpace: 'Exclusive institution space',
          support: 'Priority support and training',
        },
        paymentMethod: 'Payment method',
        seePaymentMethod: 'See payment method',
        replacePaymentMethod: 'Replace payment method',
        paypalEmail: 'PayPal email: ',
        expires: 'Expires: ',
        cardEndingIn: (brand, last4) => `${brand} card ending in ${last4}`,
        removePaymentMethod: 'Remove payment method',
        addPaymentMethod: 'Add payment method',
        promoCode: 'Promo code',
        apply: 'Apply',
        promoCodePlaceholder: 'Insert here your promo code',
        promoCodeInvalidError: 'The promo code is expired or invalid',
      },
      danger: {
        dangerHeader: 'Danger zone',
        curriculum: 'Curriculum',
        changeCurriculum: 'Change curriculum',
        changeCurriculumWarning: 'Are you sure you want to change your curriculum? Changing your curriculum will turn all your public exercises and assessments private. The contents of the explore page will also change to match your new curriculum.',
      },
    },
    schoolInvite: {
      header: 'Welcome to Intuitivo',
      description1: 'You received an invite from your school:',
      description2: 'Create an account and start collaborating!',
      continueWith: 'Continue with',
      changeAccount1: 'This is not your account?',
      changeAccount2: 'Log out',
    },
    maintenance: {
      label: 'Maintenance',
      quote: 'Tobias is working hard to bring Intuitivo back online, come back later...',
    },
    features: {
      free: {
        'create-test': (limit) => `Create up to ${limit} ${limit === 1 ? 'test' : 'tests'}`,
        'create-exercise': (limit) => `Create up to ${limit} ${limit === 1 ? 'exercise' : 'exercises'}`,
        'create-rubric': (limit) => `Create up to ${limit} ${limit === 1 ? 'rubric' : 'rubrics'}`,
        'create-group': (limit) => `Create up to ${limit} ${limit === 1 ? 'group' : 'groups'}`,
        sections: (limit) => `Create up to ${limit} ${limit === 1 ? 'section' : 'sections'}`,
        'export-versions': (limit) => `Export up to ${limit} ${limit === 1 ? 'version' : 'versions'}`,
      },
      premium: {
        'create-test': () => 'Create unlimited tests',
        'create-exercise': () => 'Create unlimited exercises',
        'create-rubric': () => 'Create unlimited rubrics',
        'create-group': () => 'Create unlimited groups',
        sections: () => 'Create unlimited sections',
        'section-timer': () => 'Timer in sections',
        navigation: () => 'Create non-linear assessments and sections',
        connecting: () => 'Publish assessments with connecting exercises',
        segmentation: () => 'Publish assessments with segmentation exercises',
        'hide-intuitivo-logo-export': () => 'Export assessments and resolutions without the Intuitivo logo',
        feedback: () => 'Give feedback to students',
        'export-versions': () => 'Export unlimited versions',
        'advanced-statistics': () => 'Advanced assessment statistics',
        'text-annotation-comments': () => 'Annotations with comments',
      },
    },
    limbo: {
      title: 'No space found!',
      description: 'Your Intuitivo school space was deactivated! If you still want to continue using Intuitivo individually, with our free and limited plan, create a personal space by clicking the button below and access the settings page to change your e-mail to a personal e-mail, if you haven\'t already.',
      addPersonalSpace: 'Add personal space',
    },
    curricula: {
      pt_PT: 'Portuguese',
      pt_BR: 'Portuguese (Brazil)',
      es_ES: 'Spanish',
      other: 'Other',
    },
  },
  pt: {
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    submit: 'Submeter',
    hide: 'Esconder',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Eliminar',
    remove: 'Remover',
    saveChanges: 'Guardar alterações',
    changesSaved: 'As alterações feitas foram gravadas com sucesso',
    copy: 'Copiar',
    oops: 'Ups! Aconteceu algo de errado!',
    date: 'Data',
    previous: 'Anterior',
    next: 'Seguinte',
    en: 'Inglês',
    pt: 'Português',
    esES: 'Espanhol',
    ptBR: 'Português (Brasil)',
    home: 'Início',
    unauthorizedMessage: 'Parece que ainda não és suficientemente qualificado para estar nesta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que te perdeste no nosso site... Talvez não seja assim tão Intuitivo.',
    errorMessage: 'Algo de errado aconteceu... Vamos verificar e corrigir o mais rápido possível.',
    noPermissionSpace: 'Não tem permissões para aceder a este espaço.',
    account: 'Conta',
    signout: 'Sair',
    send: 'Enviar',
    info: 'Informação',
    help: 'Ajuda',
    feedback: 'Sugestões',
    showMore: 'Mostrar mais',
    chooseSpace: 'Seleciona um espaço',
    changeLang: 'Mudar linguagem',
    logout: 'Terminar sessão',
    or: 'Ou',
    tutorial: 'Tutoriais',
    confirm: 'Confirmar',
    internetWentWrong: 'Algo não correu bem. Por favor verifique a sua conexão à internet e tente novamente',
    month: 'mês',
    save: 'Guardar',
    appKeywords: {
      classes: 'Aulas',
      tests: 'Testes',
      threads: 'Fóruns',
      admin: 'Administrador',
      lesson: 'Aula',
      subject: 'Área disciplinar',
      'class': 'Turma',
      student: 'Estudante',
      students: 'Estudantes',
      grade: 'Nota',
      personalAccount: 'Conta pessoal',
      personalSpace: 'Espaço pessoal',
      groups: 'Grupos',
      exercises: 'Exercícios',
      explore: 'Explorar',
      assessments: 'Avaliações',
      rubrics: 'Rubricas',
      events: 'Eventos',
      synchronization: 'Sincronização',
      teachers: 'Professores',
      exams: 'Exames',
    },
    login: {
      header: 'Iniciar sessão',
      description: 'Bem-vindo à Intuitivo',
      emailLabel: 'E-mail ou nome de utilizador',
      emailPlaceholder: 'Insere aqui o teu e-mail ou nome de utilizador',
      emailError: 'Tens de preencher este campo com o teu e-mail.',
      passwordLabel: 'Palavra-passe',
      passwordPlaceholder: 'Insere aqui a tua palavra-passe',
      passwordError: 'Tens de preencher este campo com a tua password.',
      submitLogin: 'Entrar',
      wrongCredentials: 'O teu e-mail ou password não correspondem',
      forgotPassword: 'Esqueci-me da minha palavra-passe',
      dontHaveAccount: 'Não tens uma conta?',
      createOne: 'Cria uma',
      loginWithMicrosoft: 'Iniciar sessão com a Microsoft',
      loginWithMicrosoftError: 'Inicie sessão com a sua conta Microsoft por favor',
      loginWithMicrosoftNoAccount: 'Não existe nenhuma conta com esse e-mail. Crie uma conta agora!',
      loginWithMicrosoftRegularAccountExists: 'Esta conta não está conectada à Microsoft. Inicie sessão com as suas credenciais!',
      exceededMaxAttempts: 'Você excedeu o máximo de tentativas de autenticação. Clique em esqueci-me da minha palavra-passe para continuar',
      poweredBy: 'Powered by',
    },
    register: {
      header: 'Criar conta',
      description: 'Bem-vindo à Intuitivo',
      nameLabel: 'Nome',
      namePlaceholder: 'Insere aqui o teu nome',
      nameEmptyError: 'O nome não pode estar vazio',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insere aqui o teu e-mail',
      emailEmptyError: 'O e-mail não pode ficar vazio',
      emailInvalidError: 'O e-mail introduzido não é válido',
      emailExistsError: 'Já existe uma conta com o e-mail introduzido',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insere aqui a tua password',
      passwordLengthError: 'A password deve conter pelo menos 8 caracteres',
      passwordSpecialCharsError: 'A password deve conter pelo menos um caracter especial',
      passwordUpperCharsError: 'A password deve conter pelo menos uma letra maiúscula',
      passwordNumberError: 'A password deve conter pelo menos um número',
      repeatPasswordLabel: 'Repetir password',
      repeatPasswordPlaceholder: 'Insere aqui a tua password',
      passwordsDifferentError: 'As passwords devem ser iguais',
      createAccount: 'Criar conta',
      alreadyHaveAccount: 'Já tens uma conta?',
      login: 'Inicia sessão',
      registerWithMicrosoft: 'Criar conta com a Microsoft',
    },
    onboarding: {
      title: 'Bem-vindo',
      header: 'Antes de continuar, ajude-nos a melhorar a sua experiência',
      doThisLater: 'Preencher mais tarde',
    },
    recover: {
      header: 'Recuperar password',
      description: 'Um e-mail será enviado para o seu endereço de e-mail onde poderá mudar a sua password.',
      descriptionPassword: 'Escreve aqui uma nova password para substituir a tua atual.',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insere aqui o teu e-mail',
      emailSent: 'O e-mail foi enviado com sucesso. Por favor, verifique a sua caixa de correio',
      emailError: 'O e-mail fornecido está incorreto.',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insere aqui a tua password',
      passwordRepeatLabel: 'Repetir Password',
      passwordRepeatPlaceholder: 'Insere aqui a tua password de novo',
      passwordUpdated: 'A password foi atualizada com sucesso',
      passwordMinimumChars: 'A password deve conter pelo menos 8 caracteres, uma letra e um algarismo.',
      passwordLengthError: 'A password deve conter pelo menos 8 caracteres',
      passwordSpecialCharsError: 'A password deve conter pelo menos um caracter especial',
      passwordUpperCharsError: 'A password deve conter pelo menos uma letra maiúscula',
      passwordNumberError: 'A password deve conter pelo menos um número',
      passwordsDifferentError: 'As passwords devem ser iguais',
    },
    profile: {
      header: 'Gestão de conta',
      description: 'Aqui podes gerir a tua conta',
      fullName: 'Nome',
      email: 'E-mail',
      updatePassword: 'Mudar password',
      passwordMissing: 'Ambos os campos devem ser preenchidos',
      passwordError: 'As passwords não coincidem',
      passwordHint: 'Utilizar 8 ou mais caracteres com uma mistura de letras, números e caracteres especiais',
      newPassword: 'Nova password',
      confirmPassword: 'Confirmar nova password',
      newPasswordPlaceholder: 'Escreve aqui a nova password',
      confirmPasswordPlaceholder: 'Escreve aqui novamente a nova password',
      userName: 'Nome de utilizador',
      editInformation: 'Editar informação',
      nameRequiredError: 'O nome é obrigatório',
      emailRequiredError: 'O e-mail é obrigatório',
      emailInvalidError: 'O e-mail introduzido é inválido',
      profileInformation: {
        informationUpdateSuccess: 'A sua informação foi atualizada com sucesso',
      },
      profilePreferences: {
        profilePreferences: 'Preferências',
        subjects: 'Áreas Disciplinares',
        years: 'Anos',
        country: 'País',
        curriculum: 'Currículo',
        selectCountry: 'Selecione um país',
        selectCurriculum: 'Selecione um currículo',
        editProfilePreferences: 'Editar preferências',
        noSubjects: 'Não tem nenhuma área disciplinar selecionada',
        noYears: 'Não tem nenhum ano selecionado',
        personalSpace: 'Espaço pessoal',
        personalSpaceDescription: 'O espaço pessoal é onde podes criar os teus conteúdos separados dos conteúdos da tua escola e onde podes criar grupos com outros membros da comunidade.',
        personalSpaceCheckboxLabel: 'Ativar espaço pessoal',
      },
      security: {
        securityHeader: 'Segurança',
        changePassword: 'Alterar palavra-passe',
        linkWithMicrosoft: 'Conectar com a Microsoft',
        unlinkMicrosoftAccount: 'Desconectar conta Microsoft',
        unlinkMicrosoftAccountInfo: 'Tem a certeza que quer desconectar a sua conta Microsoft? Poderá reconectar a qualquer altura no futuro.',
        unlinkMicrosoftAccountSuccess: 'A sua conta Microsoft foi desconectada com sucesso',
        unlinkMicrosoftAccountDisabled: 'Tem de criar uma palavra-passe antes de desconectar a sua conta Microsoft',
        emailAlreadyInUse: 'Este e-mail já está a ser utilizado por outro utilizador',
        accountLinkedWithMicrosoftSuccess: 'A sua conta Microsoft foi conectada com sucesso',
        createPassword: 'Criar palavra-passe',
      },
      plans: {
        plan: 'Meu plano',
        plans: 'Planos',
        free: 'Grátis',
        basic: 'Básico',
        schools: 'Escolas',
        premium: 'Premium',
        forTeachers: 'Para professores',
        forSchools: 'Para instituições',
        nextPayment: 'Próximo pagamento',
        onRequest: 'Sob consulta',
        contactUs: 'Contacte-nos!',
        recommendSchool: 'Recomendar escola',
        switchToPlan: 'Mudar para este plano',
        switchPlan: 'Gerir plano',
        cancelSubscription: 'Cancelar subscrição',
        cancelTip: (endDate) => `Vai manter todas as funcionalidades Premium até ao fim da subscrição em ${endDate}`,
        continueSubscription: 'Retomar subscrição',
        subscribedUntil: 'Com subscrição até',
        planDescription: {
          free: 'O essencial da Intuitivo, disponível para todos.',
          premium: 'A melhor ferramenta de avaliação, sem limites.',
          schools: 'A solução completa para instituições de ensino.',
        },
        monthly: 'Mensal',
        yearly: 'Anual',
        billedMonthly: 'pago mensalmente',
        billedYearly: 'pago anualmente',
        yourSubscription: 'A sua subscrição',
        total: 'Total',
        andPay: 'e pagar',
        taxInfo: ' de IVA incluído',
        includedTax: 'IVA inc.',
        alreadyOnThatPlan: 'Já tem uma subscrição desse plano',
        discountPrimaryText: (discountName) => `${discountName} com `,
        discountHighlightText: (limitDate) => `desconto até ${limitDate}!`,
        discountTooltip: (discountName, limitDate, yearlyDiscount, monthlyDiscount) => `${discountName} com desconto até ${limitDate}, com ${yearlyDiscount}% de desconto no plano anual${monthlyDiscount ? ` e ${monthlyDiscount}% no plano mensal.` : '.'}`,
        premiumUser: 'Utilizador Premium',
        getPremium: 'Obter Premium',
        schoolsFeatures: {
          premium: 'Tudo do plano premium',
          adminPanel: 'Painel de administração',
          schoolSpace: 'Espaço exclusivo da instituição',
          support: 'Suporte prioritário e formação',
        },
        paymentMethod: 'Método de pagamento',
        seePaymentMethod: 'Ver método de pagamento',
        replacePaymentMethod: 'Substituir método de pagamento',
        paypalEmail: 'Email do PayPal: ',
        expires: 'Expira: ',
        cardEndingIn: (brand, last4) => `Cartão ${brand} acabado em ${last4}`,
        removePaymentMethod: 'Remover método de pagamento',
        addPaymentMethod: 'Adicionar método de pagamento',
        promoCode: 'Código promocional',
        apply: 'Aplicar',
        promoCodePlaceholder: 'Insira aqui o seu código promocional',
        promoCodeInvalidError: 'O código promocional expirou ou é inválido',
      },
      danger: {
        dangerHeader: 'Zona de perigo',
        curriculum: 'Currículo',
        changeCurriculum: 'Mudar currículo',
        changeCurriculumWarning: 'Tem a certeza que quer mudar de currículo? Mudar de currículo vai fazer com que todos os seus exercícios e avaliações públicas voltem para privados. Os conteúdos da página de explorar vão passar a refletir o novo currículo.',
      },
    },
    schoolInvite: {
      header: 'Bem-vindo à Intuitivo',
      description1: 'Recebeu um convite da sua escola:',
      description2: 'Crie uma conta e comece já a colaborar!',
      continueWith: 'Continuar com',
      changeAccount1: 'Esta conta não é sua?',
      changeAccount2: 'Sair',
    },
    maintenance: {
      label: 'Manutenção',
      quote: 'O Tobias está a trabalhar arduamente para trazer a Intuitivo de volta, por favor volte mais tarde...',
    },
    features: {
      free: {
        'create-test': (limit) => `Crie até ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'}`,
        'create-exercise': (limit) => `Crie até ${limit} ${limit === 1 ? 'exercício' : 'exercícios'}`,
        'create-rubric': (limit) => `Crie até ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'}`,
        'create-group': (limit) => `Crie até ${limit} ${limit === 1 ? 'grupo' : 'grupos'}`,
        sections: (limit) => `Crie até ${limit} ${limit === 1 ? 'secção' : 'secções'}`,
        'export-versions': (limit) => `Exporte até ${limit} ${limit === 1 ? 'versão' : 'versões'}`,
      },
      premium: {
        'create-test': () => 'Crie avaliações ilimitadas',
        'create-exercise': () => 'Crie exercícios ilimitados',
        'create-rubric': () => 'Crie rubricas ilimitadas',
        'create-group': () => 'Crie grupos ilimitados',
        sections: () => 'Crie secções ilimitadas',
        'section-timer': () => 'Use temporizadores nas secções',
        navigation: () => 'Crie avaliações e secções não lineares',
        connecting: () => 'Publique testes com exercícios de conectar',
        segmentation: () => 'Publique testes com exercícios de segmentação',
        'hide-intuitivo-logo-export': () => 'Exporte avaliações e resoluções sem o logo da Intuitivo',
        feedback: () => 'Dê feedback aos seus estudantes',
        'export-versions': () => 'Exporte versões ilimitadas',
        'advanced-statistics': () => 'Estatísticas de avaliação avançadas',
        'text-annotation-comments': () => 'Anotações com comentários',
      },
    },
    limbo: {
      title: 'Nenhum espaço encontrado!',
      description: 'O espaço da sua escola na Intuitivo foi desativado! Se pretender continuar a usar a Intuitivo de forma independente, com o nosso plano gratuito e limitado, crie um espaço pessoal clicando no botão abaixo e aceda às definições para alterar o e-mail para o seu e-mail pessoal, caso ainda não o tenha feito.',
      addPersonalSpace: 'Adicionar espaço pessoal',
    },
    curricula: {
      pt_PT: 'Português',
      pt_BR: 'Português (Brasil)',
      es_ES: 'Espanhol',
      other: 'Outro',
    },
  },
  esES: {
    add: 'Añadir',
    create: 'Crear',
    publish: 'Publicar',
    submit: 'Enviar',
    hide: 'Ocultar',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Borrar',
    'delete': 'Eliminar',
    remove: 'Eliminar',
    saveChanges: 'Guardar cambios',
    changesSaved: 'Los cambios realizados se guardaron correctamente',
    copy: 'Copiar',
    oops: '¡Vaya! ¡Algo salió mal!',
    date: 'Fecha',
    previous: 'Anterior',
    next: 'Siguiente',
    en: 'Inglés',
    pt: 'Portugués',
    esES: 'Español',
    ptBR: 'Portugués (Brasil)',
    unauthorizedMessage: 'Parece que aún no estás calificado para estar en esta área... ¡Quizás el próximo año!',
    notFoundMessage: '¡Oh no! Parece que te has perdido en nuestro sitio web... Quizás no es tan intuitivo.',
    errorMessage: 'Algo salió mal... Estamos trabajando para solucionarlo lo antes posible.',
    noPermissionSpace: 'No tienes permisos para acceder a este espacio.',
    home: 'Inicio',
    account: 'Cuenta',
    signout: 'Cerrar sesión',
    send: 'Enviar',
    info: 'Información',
    help: 'Ayuda',
    feedback: 'Comentarios',
    showMore: 'Mostrar más',
    chooseSpace: 'Selecciona un espacio',
    changeLang: 'Cambiar idioma',
    logout: 'Cerrar sesión',
    or: 'O',
    tutorial: 'Tutoriales',
    confirm: 'Confirmar',
    internetWentWrong: 'Algo salió mal. Por favor, verifica tu conexión a Internet e inténtalo de nuevo',
    month: 'mes',
    save: 'Guardar',
    appKeywords: {
      classes: 'Clases',
      tests: 'Pruebas',
      threads: 'Hilos',
      admin: 'Administrador',
      lesson: 'Lección',
      subject: 'Asignatura',
      'class': 'Clase',
      student: 'Estudiante',
      students: 'Estudiantes',
      grade: 'Nota',
      personalAccount: 'Cuenta personal',
      personalSpace: 'Espacio personal',
      groups: 'Grupos',
      exercises: 'Ejercicios',
      explore: 'Explorar',
      assessments: 'Evaluaciones',
      rubrics: 'Rúbricas',
      events: 'Eventos',
      synchronization: 'Sincronización',
      teachers: 'Profesores',
      exams: 'Exámenes',
    },
    login: {
      header: 'Iniciar sesión',
      description: 'Bienvenido a Intuitivo',
      emailLabel: 'Correo electrónico o nombre de usuario',
      emailPlaceholder: 'Introduce aquí tu correo electrónico o nombre de usuario',
      emailError: 'Debes completar este campo con tu correo electrónico.',
      passwordLabel: 'Contraseña',
      passwordPlaceholder: 'Introduce aquí tu contraseña',
      passwordError: 'Debes completar este campo con tu contraseña.',
      submitLogin: 'Iniciar sesión',
      wrongCredentials: 'Tu correo electrónico o contraseña no coinciden',
      forgotPassword: 'Olvidé mi contraseña',
      dontHaveAccount: '¿No tienes una cuenta?',
      createOne: 'Crea una',
      loginWithMicrosoft: 'Iniciar sesión con Microsoft',
      loginWithMicrosoftError: 'Por favor, inicia sesión con tu cuenta de Microsoft',
      loginWithMicrosoftNoAccount: 'No existe ninguna cuenta con ese correo electrónico. ¡Crea una cuenta ahora!',
      loginWithMicrosoftRegularAccountExists: 'Esta cuenta no está vinculada a Microsoft. ¡Inicia sesión con tus credenciales!',
      exceededMaxAttempts: 'Has excedido el máximo de intentos de inicio de sesión permitidos. Haz clic en olvidé mi contraseña para continuar',
      poweredBy: 'Desarrollado por',
    },
    register: {
      header: 'Crear cuenta',
      description: 'Bienvenido a Intuitivo',
      nameLabel: 'Nombre',
      namePlaceholder: 'Introduce aquí tu nombre',
      nameEmptyError: 'El nombre no puede estar vacío',
      emailLabel: 'Correo electrónico',
      emailPlaceholder: 'Introduce aquí tu correo electrónico',
      emailEmptyError: 'El correo electrónico no puede estar vacío',
      emailInvalidError: 'El correo electrónico introducido debe ser válido',
      emailExistsError: 'Ya existe una cuenta con esa dirección de correo electrónico',
      passwordLabel: 'Contraseña',
      passwordPlaceholder: 'Introduce aquí tu contraseña',
      passwordLengthError: 'La contraseña debe tener al menos 8 caracteres',
      passwordSpecialCharsError: 'La contraseña debe contener al menos un carácter especial',
      passwordUpperCharsError: 'La contraseña debe contener al menos una letra mayúscula',
      passwordNumberError: 'La contraseña debe contener al menos un número',
      repeatPasswordLabel: 'Repetir contraseña',
      repeatPasswordPlaceholder: 'Introduce aquí tu contraseña nuevamente',
      passwordsDifferentError: 'Las contraseñas deben ser iguales',
      createAccount: 'Crear cuenta',
      alreadyHaveAccount: '¿Ya tienes una cuenta?',
      login: 'Inicia sesión',
      registerWithMicrosoft: 'Crear cuenta con Microsoft',
    },
    recover: {
      header: 'Recuperar contraseña',
      description: 'Se enviará un correo electrónico a tu dirección de correo donde podrás cambiar tu contraseña.',
      descriptionPassword: 'Escribe una nueva contraseña para reemplazar la actual.',
      emailLabel: 'Correo electrónico',
      emailPlaceholder: 'Introduce aquí tu correo electrónico',
      emailSent: 'El correo electrónico se envió correctamente. Por favor, revisa tu bandeja de entrada',
      emailError: 'El correo electrónico proporcionado es incorrecto.',
      passwordLabel: 'Contraseña',
      passwordPlaceholder: 'Introduce aquí tu contraseña',
      passwordRepeatLabel: 'Repetir contraseña',
      passwordRepeatPlaceholder: 'Introduce aquí tu contraseña nuevamente',
      passwordUpdated: 'La contraseña se actualizó correctamente',
      passwordMinimumChars: 'La contraseña debe contener al menos 8 caracteres, una letra y un número.',
      passwordsDifferentError: 'Las contraseñas deben ser iguales.',
      passwordLengthError: 'La contraseña debe tener al menos 8 caracteres',
      passwordSpecialCharsError: 'La contraseña debe contener al menos un carácter especial',
      passwordUpperCharsError: 'La contraseña debe contener al menos una letra mayúscula',
      passwordNumberError: 'La contraseña debe contener al menos un número',
    },
    profile: {
      header: 'Gestión de cuenta',
      description: 'Aquí puedes gestionar tu cuenta',
      fullName: 'Nombre',
      email: 'Correo electrónico',
      updatePassword: 'Cambiar contraseña',
      passwordMissing: 'Ambos campos deben estar completos',
      passwordError: 'Las contraseñas no coinciden',
      passwordHint: 'Usa 8 o más caracteres con una combinación de letras, números y caracteres especiales',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirmar nueva contraseña',
      newPasswordPlaceholder: 'Escribe aquí la nueva contraseña',
      confirmPasswordPlaceholder: 'Escribe nuevamente la nueva contraseña',
      userName: 'Nombre de usuario',
      editInformation: 'Editar información',
      nameRequiredError: 'El nombre es obligatorio',
      emailRequiredError: 'El correo electrónico es obligatorio',
      emailInvalidError: 'El correo electrónico introducido es inválido',
      profileInformation: {
        informationUpdateSuccess: 'La información se actualizó correctamente',
      },
      profilePreferences: {
        profilePreferences: 'Preferencias de perfil',
        subjects: 'Asignaturas',
        years: 'Años',
        country: 'País',
        curriculum: 'Currículo',
        selectCountry: 'Selecciona un país',
        selectCurriculum: 'Selecciona un currículo',
        editProfilePreferences: 'Editar preferencias',
        noSubjects: 'Aún no has seleccionado ninguna asignatura',
        noYears: 'Aún no has seleccionado ningún año',
        personalSpace: 'Espacio personal',
        personalSpaceDescription: 'Un espacio personal es donde puedes crear tus contenidos por separado de tu espacio escolar y crear grupos con otros miembros de la comunidad.',
        personalSpaceCheckboxLabel: 'Activar espacio personal',
      },
      security: {
        securityHeader: 'Seguridad',
        changePassword: 'Cambiar contraseña',
        linkWithMicrosoft: 'Vincular con Microsoft',
        unlinkMicrosoftAccount: 'Desvincular cuenta de Microsoft',
        unlinkMicrosoftAccountInfo: '¿Estás seguro de que deseas desvincular tu cuenta de Microsoft? Podrás volver a conectarla en cualquier momento en el futuro.',
        unlinkMicrosoftAccountSuccess: 'Tu cuenta de Microsoft se desvinculó correctamente',
        unlinkMicrosoftAccountDisabled: 'Debes crear una contraseña antes de desvincular tu cuenta de Microsoft',
        emailAlreadyInUse: 'Este correo electrónico ya está siendo utilizado por otro usuario',
        accountLinkedWithMicrosoftSuccess: 'Tu cuenta de Microsoft se vinculó correctamente',
        createPassword: 'Crear contraseña',
      },
      plans: {
        plan: 'Mi plan',
        plans: 'Planes',
        free: 'Gratis',
        basic: 'Básico',
        schools: 'Escuelas',
        forTeachers: 'Para profesores',
        forSchools: 'Para instituciones',
        premium: 'Premium',
        nextPayment: 'Próximo pago',
        onRequest: 'A pedido',
        contactUs: '¡Contáctanos!',
        recommendSchool: 'Recomendar escuela',
        switchToPlan: 'Cambiar a este plan',
        switchPlan: 'Gestionar plan',
        cancelSubscription: 'Cancelar suscripción',
        cancelTip: (endDate) => `Permanecerás Premium hasta el final del ciclo de facturación el ${endDate}`,
        continueSubscription: 'Reanudar suscripción',
        subscribedUntil: 'Suscrito hasta',
        planDescription: {
          free: 'Lo esencial de Intuitivo, disponible para todos.',
          premium: 'La mejor herramienta de evaluación, sin límites.',
          schools: 'La solución completa para instituciones educativas.',
        },
        monthly: 'Mensual',
        yearly: 'Anual',
        billedMonthly: 'facturado mensualmente',
        billedYearly: 'facturado anualmente',
        yourSubscription: 'Tu suscripción',
        total: 'Total',
        andPay: 'y pagar',
        taxInfo: 'Impuesto incluido',
        includedTax: 'Impuesto incluido',
        alreadyOnThatPlan: 'Ya estás suscrito a ese plan',
        discountPrimaryText: (discountName) => `${discountName} con `,
        discountHighlightText: (limitDate) => `un descuento hasta ${limitDate}!`,
        discountTooltip: (discountName, limitDate, yearlyDiscount, monthlyDiscount) => `${discountName} campaña hasta ${limitDate}, con un ${yearlyDiscount}% de descuento en el plan premium anual${monthlyDiscount ? ` y un ${monthlyDiscount}% en el plan premium mensual.` : '.'}`,
        premiumUser: 'Usuario Premium',
        getPremium: 'Obtener Premium',
        schoolsFeatures: {
          premium: 'Todo en el plan Premium',
          adminPanel: 'Panel de administración',
          schoolSpace: 'Espacio exclusivo de la institución',
          support: 'Soporte prioritario y formación',
        },
        paymentMethod: 'Método de pago',
        seePaymentMethod: 'Ver método de pago',
        replacePaymentMethod: 'Reemplazar método de pago',
        paypalEmail: 'Correo electrónico de PayPal: ',
        expires: 'Expira: ',
        cardEndingIn: (brand, last4) => `Tarjeta ${brand} terminada en ${last4}`,
        removePaymentMethod: 'Eliminar método de pago',
        addPaymentMethod: 'Añadir método de pago',
        promoCode: 'Código promocional',
        apply: 'Aplicar',
        promoCodePlaceholder: 'Introduce aquí tu código promocional',
        promoCodeInvalidError: 'El código promocional ha caducado o no es válido',
      },
      danger: {
        dangerHeader: 'Zona de peligro',
        curriculum: 'Currículo',
        changeCurriculum: 'Cambiar currículo',
        changeCurriculumWarning: '¿Estás seguro de que deseas cambiar tu currículo? Cambiar tu currículo hará que todos tus ejercicios y evaluaciones públicos se conviertan en privados. El contenido de la página de explorar también cambiará para coincidir con tu nuevo currículo.',
      },
    },
    schoolInvite: {
      header: 'Bienvenido a Intuitivo',
      description1: 'Recibiste una invitación de tu escuela:',
      description2: '¡Crea una cuenta y empieza a colaborar!',
      continueWith: 'Continuar con',
      changeAccount1: '¿Esta no es tu cuenta?',
      changeAccount2: 'Cerrar sesión',
    },
    maintenance: {
      label: 'Mantenimiento',
      quote: 'Tobias está trabajando duro para traer de vuelta Intuitivo, vuelve más tarde...',
    },
    features: {
      free: {
        'create-test': (limit) => `Crea hasta ${limit} ${limit === 1 ? 'prueba' : 'pruebas'}`,
        'create-exercise': (limit) => `Crea hasta ${limit} ${limit === 1 ? 'ejercicio' : 'ejercicios'}`,
        'create-rubric': (limit) => `Crea hasta ${limit} ${limit === 1 ? 'rúbrica' : 'rúbricas'}`,
        'create-group': (limit) => `Crea hasta ${limit} ${limit === 1 ? 'grupo' : 'grupos'}`,
        sections: (limit) => `Crea hasta ${limit} ${limit === 1 ? 'sección' : 'secciones'}`,
        'export-versions': (limit) => `Exporta hasta ${limit} ${limit === 1 ? 'versión' : 'versiones'}`,
      },
      premium: {
        'create-test': () => 'Crea pruebas ilimitadas',
        'create-exercise': () => 'Crea ejercicios ilimitados',
        'create-rubric': () => 'Crea rúbricas ilimitadas',
        'create-group': () => 'Crea grupos ilimitados',
        sections: () => 'Crea secciones ilimitadas',
        'section-timer': () => 'Temporizador en secciones',
        navigation: () => 'Crea evaluaciones y secciones no lineales',
        connecting: () => 'Publica evaluaciones con ejercicios de conexión',
        segmentation: () => 'Publica evaluaciones con ejercicios de segmentación',
        'hide-intuitivo-logo-export': () => 'Exporta evaluaciones y resoluciones sin el logo de Intuitivo',
        feedback: () => 'Proporciona retroalimentación a los estudiantes',
        'export-versions': () => 'Exporta versiones ilimitadas',
        'advanced-statistics': () => 'Estadísticas avanzadas de evaluación',
        'text-annotation-comments': () => 'Anotaciones con comentarios',
      },
    },
    limbo: {
      title: '¡No se encontró ningún espacio!',
      description: '¡El espacio escolar de Intuitivo fue desactivado! Si aún deseas continuar usando Intuitivo de forma individual, con nuestro plan gratuito y limitado, crea un espacio personal haciendo clic en el botón de abajo y accede a la página de configuración para cambiar tu correo electrónico a un correo personal, si aún no lo has hecho.',
      addPersonalSpace: 'Añadir espacio personal',
    },
    curricula: {
      pt_PT: 'Portugués',
      pt_BR: 'Portugués (Brasil)',
      es_ES: 'Español',
      other: 'Otro',
    },
  },
  ptBR: {
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    submit: 'Enviar',
    hide: 'Ocultar',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Excluir',
    remove: 'Remover',
    saveChanges: 'Salvar alterações',
    changesSaved: 'As alterações realizadas foram salvas com sucesso',
    copy: 'Copiar',
    oops: 'Opa! Algo deu errado!',
    date: 'Data',
    previous: 'Anterior',
    next: 'Próximo',
    en: 'Inglês',
    pt: 'Português',
    esES: 'Espanhol',
    ptBR: 'Português (Brasil)',
    unauthorizedMessage: 'Parece que você ainda não está qualificado para acessar esta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que você se perdeu em nosso site... Talvez não seja tão intuitivo.',
    errorMessage: 'Algo deu errado... Estamos trabalhando para resolver isso o mais rápido possível.',
    noPermissionSpace: 'Você não tem permissão para acessar este espaço.',
    home: 'Início',
    account: 'Conta',
    signout: 'Sair',
    send: 'Enviar',
    info: 'Informação',
    help: 'Ajuda',
    feedback: 'Feedback',
    showMore: 'Mostrar mais',
    chooseSpace: 'Escolha um espaço',
    changeLang: 'Mudar idioma',
    logout: 'Sair',
    or: 'Ou',
    tutorial: 'Tutoriais',
    confirm: 'Confirmar',
    internetWentWrong: 'Algo deu errado. Por favor, verifique sua conexão com a Internet e tente novamente',
    month: 'mês',
    save: 'Salvar',
    appKeywords: {
      classes: 'Turmas',
      tests: 'Provas',
      threads: 'Tópicos',
      admin: 'Administrador',
      lesson: 'Lição',
      subject: 'Disciplina',
      'class': 'Turma',
      student: 'Estudante',
      students: 'Estudantes',
      grade: 'Nota',
      personalAccount: 'Conta pessoal',
      personalSpace: 'Espaço pessoal',
      groups: 'Grupos',
      exercises: 'Exercícios',
      explore: 'Explorar',
      assessments: 'Avaliações',
      rubrics: 'Rubricas',
      events: 'Eventos',
      synchronization: 'Sincronização',
      teachers: 'Professores',
      exams: 'Exames',
    },
    login: {
      header: 'Entrar',
      description: 'Bem-vindo ao Intuitivo',
      emailLabel: 'E-mail ou nome de usuário',
      emailPlaceholder: 'Digite aqui seu e-mail ou nome de usuário',
      emailError: 'Você deve preencher este campo com seu e-mail.',
      passwordLabel: 'Senha',
      passwordPlaceholder: 'Digite aqui sua senha',
      passwordError: 'Você deve preencher este campo com sua senha.',
      submitLogin: 'Entrar',
      wrongCredentials: 'Seu e-mail ou senha não correspondem',
      forgotPassword: 'Esqueci minha senha',
      dontHaveAccount: 'Não tem uma conta?',
      createOne: 'Crie uma',
      loginWithMicrosoft: 'Entrar com Microsoft',
      loginWithMicrosoftError: 'Por favor, entre com sua conta Microsoft',
      loginWithMicrosoftNoAccount: 'Não existe uma conta com esse e-mail. Crie uma conta agora!',
      loginWithMicrosoftRegularAccountExists: 'Esta conta não está vinculada à Microsoft. Faça login com suas credenciais!',
      exceededMaxAttempts: 'Você excedeu o número máximo de tentativas de login permitidas. Clique em "Esqueci minha senha" para continuar',
      poweredBy: 'Desenvolvido por',
    },
    register: {
      header: 'Criar conta',
      description: 'Bem-vindo ao Intuitivo',
      nameLabel: 'Nome',
      namePlaceholder: 'Digite aqui seu nome',
      nameEmptyError: 'O nome não pode estar vazio',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Digite aqui seu e-mail',
      emailEmptyError: 'O e-mail não pode estar vazio',
      emailInvalidError: 'O e-mail informado deve ser válido',
      emailExistsError: 'Já existe uma conta com este endereço de e-mail',
      passwordLabel: 'Senha',
      passwordPlaceholder: 'Digite aqui sua senha',
      passwordLengthError: 'A senha deve ter pelo menos 8 caracteres',
      passwordSpecialCharsError: 'A senha deve conter pelo menos um caractere especial',
      passwordUpperCharsError: 'A senha deve conter pelo menos uma letra maiúscula',
      passwordNumberError: 'A senha deve conter pelo menos um número',
      repeatPasswordLabel: 'Repetir senha',
      repeatPasswordPlaceholder: 'Digite aqui sua senha novamente',
      passwordsDifferentError: 'As senhas devem ser iguais',
      createAccount: 'Criar conta',
      alreadyHaveAccount: 'Já tem uma conta?',
      login: 'Entre',
      registerWithMicrosoft: 'Criar conta com Microsoft',
    },
    recover: {
      header: 'Recuperar senha',
      description: 'Um e-mail será enviado para o seu endereço para que você possa alterar sua senha.',
      descriptionPassword: 'Digite uma nova senha para substituir a atual.',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Digite aqui seu e-mail',
      emailSent: 'O e-mail foi enviado com sucesso. Por favor, verifique sua caixa de entrada',
      emailError: 'O e-mail informado está incorreto.',
      passwordLabel: 'Senha',
      passwordPlaceholder: 'Digite aqui sua senha',
      passwordRepeatLabel: 'Repetir senha',
      passwordRepeatPlaceholder: 'Digite aqui sua senha novamente',
      passwordUpdated: 'A senha foi atualizada com sucesso',
      passwordMinimumChars: 'A senha deve ter pelo menos 8 caracteres, uma letra e um número.',
      passwordsDifferentError: 'As senhas devem ser iguais.',
      passwordLengthError: 'A senha deve ter pelo menos 8 caracteres',
      passwordSpecialCharsError: 'A senha deve conter pelo menos um caractere especial',
      passwordUpperCharsError: 'A senha deve conter pelo menos uma letra maiúscula',
      passwordNumberError: 'A senha deve conter pelo menos um número',
    },
    profile: {
      header: 'Gerenciamento de conta',
      description: 'Aqui você pode gerenciar sua conta',
      fullName: 'Nome completo',
      email: 'E-mail',
      updatePassword: 'Alterar senha',
      passwordMissing: 'Ambos os campos devem estar preenchidos',
      passwordError: 'As senhas não coincidem',
      passwordHint: 'Use 8 ou mais caracteres com uma combinação de letras, números e caracteres especiais',
      newPassword: 'Nova senha',
      confirmPassword: 'Confirmar nova senha',
      newPasswordPlaceholder: 'Digite aqui a nova senha',
      confirmPasswordPlaceholder: 'Digite novamente a nova senha',
      userName: 'Nome de usuário',
      editInformation: 'Editar informações',
      nameRequiredError: 'O nome é obrigatório',
      emailRequiredError: 'O e-mail é obrigatório',
      emailInvalidError: 'O e-mail informado é inválido',
      profileInformation: {
        informationUpdateSuccess: 'As informações foram atualizadas com sucesso',
      },
      profilePreferences: {
        profilePreferences: 'Preferências de perfil',
        subjects: 'Disciplinas',
        years: 'Anos',
        country: 'País',
        curriculum: 'Currículo',
        selectCountry: 'Selecione um país',
        selectCurriculum: 'Selecione um currículo',
        editProfilePreferences: 'Editar preferências',
        noSubjects: 'Você ainda não selecionou nenhuma disciplina',
        noYears: 'Você ainda não selecionou nenhum ano',
        personalSpace: 'Espaço pessoal',
        personalSpaceDescription: 'Um espaço pessoal é onde você pode criar seus conteúdos separados do espaço da escola e criar grupos com outros membros da comunidade.',
        personalSpaceCheckboxLabel: 'Ativar espaço pessoal',
      },
      security: {
        securityHeader: 'Segurança',
        changePassword: 'Alterar senha',
        linkWithMicrosoft: 'Vincular com Microsoft',
        unlinkMicrosoftAccount: 'Desvincular conta Microsoft',
        unlinkMicrosoftAccountInfo: 'Tem certeza de que deseja desvincular sua conta Microsoft? Você poderá reconectá-la a qualquer momento no futuro.',
        unlinkMicrosoftAccountSuccess: 'Sua conta Microsoft foi desvinculada com sucesso',
        unlinkMicrosoftAccountDisabled: 'Você deve criar uma senha antes de desvincular sua conta Microsoft',
        emailAlreadyInUse: 'Este e-mail já está sendo usado por outro usuário',
        accountLinkedWithMicrosoftSuccess: 'Sua conta Microsoft foi vinculada com sucesso',
        createPassword: 'Criar senha',
      },
      plans: {
        plan: 'Meu plano',
        plans: 'Planos',
        free: 'Gratuito',
        basic: 'Básico',
        schools: 'Escolas',
        forTeachers: 'Para professores',
        forSchools: 'Para instituições',
        premium: 'Premium',
        nextPayment: 'Próximo pagamento',
        onRequest: 'Sob demanda',
        contactUs: 'Entre em contato!',
        recommendSchool: 'Recomendar escola',
        switchToPlan: 'Mudar para este plano',
        switchPlan: 'Gerenciar plano',
        cancelSubscription: 'Cancelar assinatura',
        cancelTip: (endDate) => `Você permanecerá Premium até o final do ciclo de faturamento em ${endDate}`,
        continueSubscription: 'Continuar assinatura',
        subscribedUntil: 'Assinado até',
        planDescription: {
          free: 'O essencial do Intuitivo, disponível para todos.',
          premium: 'A melhor ferramenta de avaliação, sem limites.',
          schools: 'A solução completa para instituições educacionais.',
        },
        monthly: 'Mensal',
        yearly: 'Anual',
        billedMonthly: 'cobrado mensalmente',
        billedYearly: 'cobrado anualmente',
        yourSubscription: 'Sua assinatura',
        total: 'Total',
        andPay: 'e pagar',
        taxInfo: 'Imposto incluído',
        includedTax: 'Imposto incluído',
        alreadyOnThatPlan: 'Você já está inscrito neste plano',
        discountPrimaryText: (discountName) => `${discountName} com `,
        discountHighlightText: (limitDate) => `um desconto até ${limitDate}!`,
        discountTooltip: (discountName, limitDate, yearlyDiscount, monthlyDiscount) => `${discountName} campanha até ${limitDate}, com um desconto de ${yearlyDiscount}% no plano premium anual${monthlyDiscount ? ` e um desconto de ${monthlyDiscount}% no plano premium mensal.` : '.'}`,
        premiumUser: 'Usuário Premium',
        getPremium: 'Obter Premium',
        schoolsFeatures: {
          premium: 'Tudo no plano Premium',
          adminPanel: 'Painel de administração',
          schoolSpace: 'Espaço exclusivo da instituição',
          support: 'Suporte prioritário e treinamento',
        },
        paymentMethod: 'Método de pagamento',
        seePaymentMethod: 'Ver método de pagamento',
        replacePaymentMethod: 'Substituir método de pagamento',
        paypalEmail: 'E-mail do PayPal: ',
        expires: 'Expira: ',
        cardEndingIn: (brand, last4) => `Cartão ${brand} terminado em ${last4}`,
        removePaymentMethod: 'Remover método de pagamento',
        addPaymentMethod: 'Adicionar método de pagamento',
        promoCode: 'Código promocional',
        apply: 'Aplicar',
        promoCodePlaceholder: 'Digite aqui seu código promocional',
        promoCodeInvalidError: 'O código promocional expirou ou é inválido',
      },
      danger: {
        dangerHeader: 'Zona de perigo',
        curriculum: 'Currículo',
        changeCurriculum: 'Mudar currículo',
        changeCurriculumWarning: 'Tem certeza de que deseja mudar seu currículo? Mudar o currículo tornará todos os seus exercícios e avaliações públicos em privados. O conteúdo da página de exploração também mudará para corresponder ao seu novo currículo.',
      },
    },
    schoolInvite: {
      header: 'Bem-vindo ao Intuitivo',
      description1: 'Você recebeu um convite da sua escola:',
      description2: 'Crie uma conta e comece a colaborar!',
      continueWith: 'Continuar com',
      changeAccount1: 'Esta não é sua conta?',
      changeAccount2: 'Sair',
    },
    maintenance: {
      label: 'Manutenção',
      quote: 'Tobias está trabalhando duro para trazer o Intuitivo de volta, volte mais tarde...',
    },
    features: {
      free: {
        'create-test': (limit) => `Crie até ${limit} ${limit === 1 ? 'prova' : 'provas'}`,
        'create-exercise': (limit) => `Crie até ${limit} ${limit === 1 ? 'exercício' : 'exercícios'}`,
        'create-rubric': (limit) => `Crie até ${limit} ${limit === 1 ? 'rúbrica' : 'rúbricas'}`,
        'create-group': (limit) => `Crie até ${limit} ${limit === 1 ? 'grupo' : 'grupos'}`,
        sections: (limit) => `Crie até ${limit} ${limit === 1 ? 'seção' : 'seções'}`,
        'export-versions': (limit) => `Exporte até ${limit} ${limit === 1 ? 'versão' : 'versões'}`,
      },
      premium: {
        'create-test': () => 'Crie provas ilimitadas',
        'create-exercise': () => 'Crie exercícios ilimitados',
        'create-rubric': () => 'Crie rúbricas ilimitadas',
        'create-group': () => 'Crie grupos ilimitados',
        sections: () => 'Crie seções ilimitadas',
        'section-timer': () => 'Temporizador em seções',
        navigation: () => 'Crie avaliações e seções não lineares',
        connecting: () => 'Publique avaliações com exercícios de conexão',
        segmentation: () => 'Publique avaliações com exercícios de segmentação',
        'hide-intuitivo-logo-export': () => 'Exporte avaliações e resoluções sem o logotipo do Intuitivo',
        feedback: () => 'Forneça feedback aos alunos',
        'export-versions': () => 'Exporte versões ilimitadas',
        'advanced-statistics': () => 'Estatísticas avançadas de avaliação',
        'text-annotation-comments': () => 'Anotações com comentários',
      },
    },
    limbo: {
      title: 'Nenhum espaço encontrado!',
      description: 'O espaço escolar do Intuitivo foi desativado! Se você ainda deseja continuar usando o Intuitivo individualmente, com nosso plano gratuito e limitado, crie um espaço pessoal clicando no botão abaixo e acesse a página de configurações para alterar seu e-mail para um e-mail pessoal, se ainda não o fez.',
      addPersonalSpace: 'Adicionar espaço pessoal',
    },
    curricula: {
      pt_PT: 'Português',
      pt_BR: 'Português (Brasil)',
      es_ES: 'Espanhol',
      other: 'Other',
    },
  },
});

export default lang;
