import React, { useEffect, useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { bootUser } from 'actions/userActions';
import api from 'api';
import { loginRequest as loginAuthRequest } from 'authConfig';
import { LOGIN_STRATEGY, SESSION_NONCE } from 'constants/cookies';
import { LOCAL_STRATEGY } from 'constants/loginStrategies';
import { ADMIN, SUPERADMIN, TEACHER } from 'constants/roles';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import useInput from 'hooks/useInput';
import usePageLogic from 'hooks/usePageLogic';
import useQuery from 'hooks/useQuery';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';
import { addCookie } from 'utils';

import Button from 'components/common/Button';
import Input from 'components/common/input/Input';
import LangSelector from 'components/common/LangSelector';
import Logo from 'components/common/Logo';
import MicrosoftButton from 'components/common/MicrosoftButton';

import { ReactComponent as LogoIAVE } from './logo_iave.module.svg';
import { ReactComponent as LogoIAVEWhite } from './logo_iave_branco.svg';
import { ReactComponent as LogoPRR } from './logo_prr.module.svg';
import useStyles from './styles';

const Login = () => {
  const classes = useStyles();
  const { loaded } = usePageLogic('Login', false);
  const toast = useToast();
  const history = useHistory();
  const [loginRequest] = useApi(api.login, true);
  const azureOauthToggle = useFeature(toggles.azureOauth);
  const iaveToggle = useFeature(toggles.iave);
  const registerToggle = useFeature(toggles.register);
  const query = useQuery();
  const exploreToggle = useFeature(toggles.explore);
  const dispatch = useDispatch();

  const [email, setEmail, emailErrors, setEmailErrors] = useInput('');
  const [password, setPassword, passwordErrors, setPasswordErrors] = useInput('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    loaded();
  }, [loaded]);

  const handleLogin = (event) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    const plan = query().get('plan');
    const subscriptionType = query().get('type');

    setLoading(true);
    let valid = true;
    const localEmailErrors = [];
    const localPasswordErrors = [];

    if (email === '') {
      localEmailErrors.push(lang.login.emailError);
      valid = false;
    }

    if (password === '') {
      localPasswordErrors.push(lang.login.passwordError);
      valid = false;
    }

    if (!valid) {
      setTimeout(() => {
        setEmailErrors(localEmailErrors);
        setPasswordErrors(localPasswordErrors);
        setLoading(false);
      }, 200);
      return;
    }

    loginRequest([], { email, password }, ({ data }) => {
      if (data.status === 0) {
        setTimeout(() => {
          addCookie(SESSION_NONCE, data.sessionToken);
          addCookie(LOGIN_STRATEGY, LOCAL_STRATEGY);
          dispatch(bootUser(data.user));

          if ([ADMIN, SUPERADMIN].includes(data.user.role)) {
            window.location = routes.admin.ref();
          } else if (plan) {
            window.location = routes.profile.ref(plan, subscriptionType);
          } else if (data.user.isFirstTime && exploreToggle && data.user.role === TEACHER) {
            history.push(routes.onboarding.ref());
          } else {
            window.location = routes.tests.ref();
          }
        }, 400);
        return;
      } else if (data.status === 3) {
        setTimeout(() => {
          toast.warning(lang.login.exceededMaxAttempts);
          setLoading(false);
        }, 200);
        return;
      } else if (data.status === 4) {
        setTimeout(() => {
          toast.warning(lang.login.loginWithMicrosoftError);
          setLoading(false);
        }, 200);
        return;
      }

      setTimeout(() => {
        toast.warning(lang.login.wrongCredentials);
        setLoading(false);
      }, 200);
    });
  };

  return (
    <div className={cx(classes.pageContainer, { iave: iaveToggle })}>
      <div className={classes.langSelectorContainer}>
        <LangSelector tipDirection="left" />
      </div>
      <div className={cx(classes.logoContainer, { iave: iaveToggle })}>
        {iaveToggle ?
          <div>
            <LogoIAVEWhite className={classes.iaveLogoWhite} />
          </div>
          :
          <Logo large dark />
        }
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.formContainer}>
          <div className={classes.loginContainer}>
            <div className={classes.loginHeader}>
              <div className={classes.loginTitle}>
                {lang.login.header}
              </div>
              <Render when={!iaveToggle}>
                <div className={classes.loginSubtitle}>
                  {lang.login.description}
                </div>
              </Render>
            </div>
            <Render when={azureOauthToggle}>
              <MicrosoftButton
                label={lang.login.loginWithMicrosoft}
                request={loginAuthRequest}
              />
              <div className={classes.loginStrategiesSeparator}>
                <div className={classes.separatorLine} />
                {lang.or.toUpperCase()}
                <div className={classes.separatorLine} />
              </div>
            </Render>
            <form onSubmit={handleLogin}>
              <Input
                type="text"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                errors={emailErrors}
                invalid={emailErrors.length !== 0}
                label={lang.login.emailLabel}
                placeholder={lang.login.emailPlaceholder}
                className={classes.input}
              />
              <div className={classes.passwordWrapper}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  errors={passwordErrors}
                  invalid={passwordErrors.length !== 0}
                  label={lang.login.passwordLabel}
                  placeholder={lang.login.passwordPlaceholder}
                  className={classes.input}
                />
                <div
                  className={classes.showPasswordButton}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </div>
              </div>
              <Button
                type="submit"
                className={classes.button}
                stretch
                loading={loading}
              >
                {lang.login.submitLogin}
              </Button>
            </form>
            <Render when={!iaveToggle}>
              <div className={classes.forgotPassword} onClick={() => history.push(routes.recover.ref())}>
                {lang.login.forgotPassword}
              </div>
              {registerToggle &&
                <div>
                  <div className={classes.separator} />
                  <div className={classes.createAccountContainer}>
                    {lang.login.dontHaveAccount}
                    <Link
                      to={routes.register.ref()}
                      className={classes.registerLink}
                    >
                      {lang.login.createOne}
                    </Link>
                  </div>
                </div>
              }
            </Render>
          </div>
        </div>
        <Render when={iaveToggle}>
          <div className={classes.iaveLogosContainer}>
            <LogoIAVE className={classes.iaveLogo} />
            <LogoPRR className={classes.prrLogo} />
          </div>
        </Render>
      </div>
    </div>
  );
};

export default Login;
