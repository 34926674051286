import React, { useEffect, useState } from 'react';
import { Button, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectPaymentMethod, selectUserData, selectUserSchools, setPaymentMethod } from 'actions/userActions';
import api from 'api';
import { FREE, PREMIUM, SCHOOLS } from 'constants/plans';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import useQuery from 'hooks/useQuery';
import lang from 'lang';
import toggles from 'toggles';

import PaymentMethodsModal from '../PaymentMethodsModal';
import PlansCardModal from '../PlansCardModal';

import useStyles from './styles';

const PlanCard = ({ plan }) => {
  const classes = useStyles();
  const userSchools = useSelector(selectUserSchools);
  const paymentMethod = useSelector(selectPaymentMethod);
  const customerId = (useSelector(selectUserData)).stripeId;
  const query = useQuery();
  const paymentMethodToggle = useFeature(toggles.paymentMethod);
  const toast = useToast();
  const [getPaymentMethodRequest] = useApi(api.getPaymentMethod);
  const dispatch = useDispatch();

  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);

  const getInitialState = () => {
    const paramsPlan = query().get('plan');
    if (!paramsPlan || (paramsPlan && paramsPlan === plan.name)) {
      return false;
    } else {
      return true;
    }
  };

  const [editPlanModal, setEditPlanModal] = useState(getInitialState);

  useEffect(() => {
    if (paymentMethodToggle && plan.name !== SCHOOLS) {
      setPaymentMethodLoading(true);
      getPaymentMethodRequest([], null, ({ data }) => {
        if (data.status === 0) {
          dispatch(setPaymentMethod(data.paymentMethod));
        }
        setPaymentMethodLoading(false);
      });
    }
  }, [query, toast, plan, dispatch, getPaymentMethodRequest, paymentMethodToggle]);

  const planDueDate = new Date(plan.nextPayment);
  const planDueDateDay = planDueDate.getDate();
  const planDueDateMonth = planDueDate.getMonth() + 1;
  const planDueDateYear = planDueDate.getFullYear();

  const getPlanType = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.basic;
      case PREMIUM:
        return lang.profile.plans.premium;
      case SCHOOLS:
        return lang.profile.plans.schools;
      default:
        break;
    }
  };

  const getPriceLabel = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.free;
      case PREMIUM:
        return plan.price ? (plan.price + '€') : lang.profile.plans.premiumUser;
      case SCHOOLS:
        return plan.isProfileCard ? userSchools.map(school => school.name).join('|') : lang.profile.plans.onRequest;
      default:
        break;
    }
  };

  const getPlanTarget = () => {
    switch (plan.name) {
      case FREE:
        return lang.profile.plans.forTeachers;
      case PREMIUM:
        return lang.profile.plans.forTeachers;
      case SCHOOLS:
        return lang.profile.plans.forSchools;
      default:
        break;
    }
  };

  const getSchools = () => {
    if ([FREE, PREMIUM].includes(plan.name)) {
      return lang.appKeywords.personalSpace;
    }
  };

  return (
    <div className={cx(classes.card, { free: plan.name === FREE, premium: plan.name === PREMIUM, schools: plan.name === SCHOOLS })}>
      <div className={classes.header}>
        <div className={cx(classes.planType, { free: plan.name === FREE, premium: plan.name === PREMIUM, schools: plan.name === SCHOOLS })}>
          {getPlanType()}
        </div>
        <div className={classes.planTarget}>
          {getPlanTarget()}
        </div>
      </div>
      <div className={classes.planSchools}>
        {getSchools()}
      </div>
      <div className={classes.planPrice}>
        {getPriceLabel()}
        {plan.name === PREMIUM && plan.price && (
          <div className={classes.perMonth}>
            {'/' + lang.month}
          </div>
        )}
      </div>
      {plan.name === PREMIUM && plan.subscriptionType === 'yearly' && (
        <div className={classes.yearlyExtraText}>
          {`(${plan.price * 12}€ ${lang.profile.plans.billedYearly})`}
        </div>
      )}
      <div className={classes.planDescription}>
        {lang.profile.plans.planDescription[plan.name]}
      </div>
      {plan.name !== FREE && plan.nextPayment && (
        <div className={classes.dueDate}>
          <div className={classes.priceInfo}>
            {plan.active ? lang.profile.plans.nextPayment : lang.profile.plans.subscribedUntil}
            {':'}
          </div>
          <div className={classes.name}>
            {planDueDateDay}
            {'/'}
            {planDueDateMonth}
            {'/'}
            {planDueDateYear}
          </div>
        </div>
      )}
      <div className={classes.buttonWrapper}>
        {((plan.name === PREMIUM && plan.price) || plan.name === FREE) && (
          <>
            <PlansCardModal
              open={editPlanModal}
              close={() => setEditPlanModal(false)}
            />
            <Button
              onClick={() => setEditPlanModal(true)}
              sibling
            >
              {lang.profile.plans.switchPlan}
            </Button>
          </>
        )}
        {paymentMethodToggle && plan.name !== SCHOOLS && (paymentMethod || (plan.name === PREMIUM && !!plan.price) || (plan.name === FREE && customerId)) && (
          <>
            <PaymentMethodsModal
              open={paymentMethodModal}
              close={() => setPaymentMethodModal(false)}
            />
            <Button
              onClick={() => setPaymentMethodModal(true)}
              sibling
              loading={paymentMethodLoading}
            >
              {paymentMethod ? lang.profile.plans.seePaymentMethod : lang.profile.plans.addPaymentMethod}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object,
};

export default PlanCard;
