import React, { useEffect } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import { useDispatch, useSelector } from 'react-redux';

import { selectHasPersonalSpace, selectUserPlan, selectUserSchools } from 'actions/userActions';
import api from 'api';
import useApi from 'hooks/useApi';
import useFeature from 'hooks/useFeature';
import useQuery from 'hooks/useQuery';
import lang from 'lang';
import toggles from 'toggles';

import PlanCard from '../PlanCard';
import Card from 'components/common/Card';

import useStyles from './styles';

const ProfilePlansCard = () => {
  const classes = useStyles();
  const userPlan = useSelector(selectUserPlan);
  const hasPersonalSpace = useSelector(selectHasPersonalSpace);
  const userSchools = useSelector(selectUserSchools);
  const query = useQuery();
  const toast = useToast();
  const [getPaymentMethodRequest] = useApi(api.getPaymentMethod);
  const dispatch = useDispatch();
  const paymentMethodToggle = useFeature(toggles.paymentMethod);

  useEffect(() => {
    if (query().get('plan') && query().get('plan') === userPlan.name) {
      toast.success(lang.profile.plans.alreadyOnThatPlan);
    }
  }, [query, toast, userPlan, dispatch, getPaymentMethodRequest, paymentMethodToggle]);

  return (
    <Card
      header={lang.profile.plans.plan}
      className={classes.card}
    >
      <div className={classes.cardSelectorWrapper}>
        {hasPersonalSpace && (
          <PlanCard
            plan={{ ...userPlan, isProfileCard: true }}
          />
        )}
        {userSchools.length > 0 && (
          <PlanCard plan={{ name: 'schools', isProfileCard: true }} />
        )}
      </div>
    </Card>
  );
};

export default ProfilePlansCard;
